<template>
    <modal ref="modalSubirArchivo" titulo="Subir archivo" guardar tamano="modal-md" adicional="Guardar" :cargando="cargando" @adicional="saveFile">
        <ValidationObserver ref="form">
            <div class="row mx-0">
                <div class="col-12">
                    <p class="f-13 ml-2">Nombre</p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:60" name="Nombre">
                        <el-input v-model="name" placeholder="Nombre del archivo" size="small" :maxlength="60" />
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-auto">
                    <p class="f-13 ml-2">Archivo</p>
                    <el-upload
                    ref="upload"
                    action="#"
                    :auto-upload="false"
                    :on-change="uploadFile"
                    :on-remove="removeFile"
                    :limit="1"
                    >
                        <div class="btn-general px-3 cr-pointer br-4">
                            <p class="py-1">Subir archivo</p>
                        </div>
                    </el-upload>
                    <p v-if="fileRequired" class="text-danger f-10">El archivo es obligatorio</p>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
import Service from '~/services/biblioteca/archivos';

export default {
    data(){
        return {
            name: null,
            file: null,
            fileRequired: false,
            cargando: false
        }
    },
    computed:{
        idCarpeta(){
            return Number(this.$route.params.idCarpeta);
        }
    },

    methods:{
        async toggle(){
            await this.limpiar();
            this.$refs.modalSubirArchivo.toggle();
        },

        async saveFile(){
            try {
                let valid = await this.$refs.form.validate();

                if(!valid || !this.file){
                    if(!this.file) this.fileRequired = true;
                    return;
                }

                this.cargando = true

                const payload = {
                    file: this.file,
                    nombre: this.name,
                    idCarpeta: this.idCarpeta
                };

                const payloadFormat = this.crearFormData(payload);

                const {data} = await Service.postFile(payloadFormat);
                if(data.success){
                    this.$notify({
                        title: 'Mensaje',
                        message: data.mensaje,
                        type: 'success'
                    });
                    this.$emit('update', data.data);
                    this.$refs.modalSubirArchivo.toggle();
                }
            } catch (error){
                this.errorCatch(e)
            } finally {
                this.cargando = false
            }
        },

        uploadFile(file){
            if(file){
                this.file = file.raw;
                this.fileRequired = false;
            }
        },

        async limpiar(){
            this.name = null;
            this.file = null;
            this.fileRequired = false;
            this.$refs.upload.clearFiles();
            this.$refs.form.reset();
        },

        removeFile(){
            this.file = null;
        }
    }
}
</script>
<style lang="scss" scoped>

</style>